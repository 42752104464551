<section class="container-fluid book_overlay">
  <div class="step_container">
    <div class="row">
      <div class="col-md-12 book-list-col">
        <div class="book_list-container">
          <div class="training-task_create_headline">
            <span class="paragraph task_chip task_chip--text">{{
              translatedText.book_list_training
            }}</span>
            <h2 class="h2 h2--bold">
              {{ translatedText.book_list_selection_text }}
            </h2>
          </div>

          <app-trial-banner
            *ngIf="trialStatus.isActive"
            [trialHeadline]="translatedText.license_books_trial_title"
            [trialText]="translatedText.license_books_trial_description"
            [expiredHeadline]="translatedText.license_books_trial_title"
            [expiredText]="translatedText.license_books_trial_description"
            [trialStatus]="trialStatus"
            [showExpirationDate]="false"
            [showButtons]="false"
          ></app-trial-banner>
          <div class="inline_text book_list_header">
            <!-- <button *ngIf="!isTask" mat-fab type="button" class="backButton close_book" (click)="closeBookList()" matSuffix>
                <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
              </button> -->
            <!-- <h2 *ngIf="!isTask" class="h2 h2--bold">Wählen Sie ein Buch, in dem gelesen werden soll</h2>
              <h2 *ngIf="isTask && taskType != 'self'" class="h2 h2--bold">Wähle ein Buch, mit dem Du die Aufgabe erledigen möchtest.</h2>
              <h2 *ngIf="isTask && taskType == 'self'" class="h2 h2--bold">Wähle ein Buch, in dem Du lesen möchtest.</h2> -->
          </div>
          <div class="book-list-filter" *ngIf="!isTask">
            <!-- search -->
            <div class="col-md-12 full-width">
              <mat-form-field class="book_list_search">
                <mat-label>{{ translatedText.book_list_search }}</mat-label>
                <input
                  matInput
                  type="text"
                  (input)="onSearchInputFilterChange($event)"
                  placeholder="{{ translatedText.book_list_search }}"
                />
              </mat-form-field>
            </div>

            <div class="row">
              <!-- grade and difficulty-->
              <div class="col-md-6">
                <mat-form-field
                  class="book_list_select book_list_select--grade"
                >
                  <mat-label>{{ translatedText.book_list_group }}</mat-label>
                  <mat-select
                    [formControl]="filterFormGroup.get('grade')"
                    panelClass="dashboard-task-select"
                    disableOptionCentering
                    multiple
                  >
                    <mat-select-trigger>
                      {{
                        generateSelectionText(
                          gradeOptions,
                          filterFormGroup.get("grade").value
                        )
                      }}
                    </mat-select-trigger>
                    <app-select-all-option
                      [control]="filterFormGroup.get('grade')"
                      [values]="gradeOptions"
                      [valuetype]="'object'"
                      (selectionChange)="onSelectChange($event, 'grade')"
                      [translatedText]="translatedText"
                    ></app-select-all-option>
                    <mat-option
                      *ngFor="let grade of gradeOptions"
                      [value]="grade?.value"
                    >
                      {{ grade.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="book_list_selectbook_list_select--difficulty"
                  ><!-- Please select the grade first -->
                  <mat-label>{{
                    translatedText.book_list_difficulty_level
                  }}</mat-label>
                  <mat-select
                    [formControl]="filterFormGroup.get('difficulty')"
                    panelClass="dashboard-task-select"
                    disableOptionCentering
                    multiple
                  >
                    <mat-select-trigger>
                      {{
                        generateSelectionText(
                          difficultyOptions,
                          filterFormGroup.get("difficulty").value
                        )
                      }}
                    </mat-select-trigger>
                    <app-select-all-option
                      [control]="filterFormGroup.get('difficulty')"
                      [values]="difficultyOptions"
                      [valuetype]="'object'"
                      (selectionChange)="onSelectChange($event, 'difficulty')"
                      [translatedText]="translatedText"
                    ></app-select-all-option>
                    <mat-option
                      *ngFor="let difficulty of difficultyOptions"
                      [value]="difficulty?.value"
                    >
                      {{ difficulty.viewValue }}
                    </mat-option>
                  </mat-select>
                  <mat-hint [hidden]="diffFieldEnabled">{{
                    translatedText.book_list_select_class_first
                  }}</mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- age, categories and publisher -->

              <div class="col-md-6">
                <mat-form-field
                  class="book_list_select book_list_select--categories"
                >
                  <mat-label>{{ translatedText.book_list_genre }}</mat-label>

                  <mat-select
                    [formControl]="filterFormGroup.get('categories')"
                    panelClass="dashboard-task-select"
                    disableOptionCentering
                    multiple
                  >
                    <mat-select-trigger>
                      {{
                        generateSelectionText(
                          categoryOptions,
                          filterFormGroup.get("categories").value
                        )
                      }}
                    </mat-select-trigger>
                    <app-select-all-option
                      [control]="filterFormGroup.get('categories')"
                      [values]="categoryOptions"
                      [valuetype]="'object'"
                      (selectionChange)="onSelectChange($event, 'categories')"
                      [translatedText]="translatedText"
                    ></app-select-all-option>
                    <mat-option
                      *ngFor="let category of categoryOptions"
                      [value]="category?.value"
                    >
                      {{ category.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field
                  class="book_list_select book_list_select--publisher"
                >
                  <mat-label>{{
                    translatedText.book_list_publisher
                  }}</mat-label>
                  <mat-select
                    [formControl]="filterFormGroup.get('publisher')"
                    panelClass="dashboard-task-select"
                    disableOptionCentering
                    multiple
                  >
                    <mat-select-trigger>
                      {{
                        generateSelectionText(
                          publisherOptions,
                          filterFormGroup.get("publisher").value
                        )
                      }}
                    </mat-select-trigger>
                    <app-select-all-option
                      [control]="filterFormGroup.get('publisher')"
                      [values]="publisherOptions"
                      [valuetype]="'object'"
                      (selectionChange)="onSelectChange($event, 'publisher')"
                      [translatedText]="translatedText"
                    ></app-select-all-option>
                    <mat-option
                      *ngFor="let publisher of publisherOptions"
                      [value]="publisher?.value"
                    >
                      {{ publisher.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
          <div class="book_list_content" *ngIf="!isLoading">
            <div *ngFor="let category of filterCategories">
              <div class="row">
                <div class="col-md-12" *ngIf="categoryCheck(category)">
                  <h2>
                    {{
                      translatedText[
                        "book_preferences_" +
                          this.replaceUmlautsAndLowercaseAndPrefix(category)
                      ]
                    }}
                  </h2>
                </div>
                <div class="col-md-12">
                  <div class="book_wrap">
                    <div
                      *ngFor="let book of categoryFilter(category)"
                      class="book"
                      (click)="
                        setBook(
                          book?._book?.isbn,
                          book?._book?.title,
                          book?._book?.book_description,
                          book?._book?.author,
                          book?._contenthub?.dsef_version
                        )
                      "
                    >
                      <div class="book_cover">
                        <img
                          src="{{
                            getBookCoverByIsbn(book?._book?.isbn, 'normal')
                          }}"
                          alt="{{ book?._book?.title }}"
                          loading="lazy"
                          (error)="handleImageError($event, book?._book)"
                          *ngIf="book?._book"
                        />
                      </div>
                      <span [innerHtml]="book?._book?.title"></span>
                      <span
                        class="paragraph paragraph--bold"
                        [innerHtml]="book?.title"
                      ></span>
                      <div class="view_button book_button">
                        <button
                          *ngIf="!isTask"
                          (click)="
                            setBook(
                              book?._book?.isbn,
                              book?._book?.title,
                              book?._book?.book_description,
                              book?._book?.author,
                              book?._contenthub?.dsef_version
                            )
                          "
                          mat-flat-button
                          color="primary"
                          type="button"
                          [disabled]="!licenseActive"
                        >
                          <mat-icon
                            svgIcon="streamline_outlined:eye"
                          ></mat-icon>
                          {{ translatedText.book_list_view }}
                        </button>
                        <button
                          *ngIf="isTask"
                          (click)="
                            openBookPreviewTask(
                              book?._book?.isbn,
                              book?._book?.title,
                              book?._book?.book_description,
                              book?._book?.author
                            )
                          "
                          mat-flat-button
                          color="gradient"
                          type="button"
                          [disabled]="!licenseActive"
                        >
                          <mat-icon
                            class="icon_transparent"
                            svgIcon="streamline_outlined:eye"
                          ></mat-icon>
                          {{ translatedText.book_list_view }}
                        </button>
                      </div>
                      <div *ngIf="!isTask" class="blur_bg"></div>
                      <!-- <div *ngIf="isTask" (click)="openBookPreviewTask(book?._book?.isbn, book?._book?.title, book?._book?.book_description, book?._book?.author)" class="blur_bg"></div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="book_list_empty" *ngIf="dataSource?.data?.length == 0">
              <img
                class="empty-books-img"
                src="../../../assets/no-books.svg"
                alt=""
              />
              <p class="paragraph paragraph--bold paragraph--xl">
                {{ translatedText.book_list_not_found }}
              </p>
            </div>
          </div>
        </div>
        <div class="book_preview_footer">
          <button
            *ngIf="!isTask"
            mat-flat-button
            color="secondary"
            (click)="closeBookList()"
          >
            {{ translatedText.book_list_back }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
