export const environment = {
  production: true,
  ssl: true,
  appVersion: require('../../package.json').version,
  hotfixVersion: require('../../package.json').hotfix,
  isApp: false,
  evolutionAPI: 'https://evolution-api-staging.k8s-scw.digi-sapiens.net',
  CLOUDAPI_SERVER: 'https://api-readalizer-staging.k8s-scw.digi-sapiens.net',
  BOOKREADERAPI_SERVER:
    'https://ebcont-reader-backend-staging.k8s-scw.digi-sapiens.net',
  externalAssets: 'https://cdn-internal-assets.k8s-scw-prod.digi-sapiens.net',
  userbackActive: true,
  userbackAccessToken: 'P-dDyH07CiM5dc2GvDmwL9MPCKg',
  sentryDSN:
    'https://e6d1e772814f4bb689e1c2281eaa0b10@o1330715.ingest.sentry.io/4505511324155904',
  sentryEnvironment: 'staging',
  gaTrackId: 'G-R2VP875K24',
  clientId: 'stage.laletu.app',
  language: 'de_de',
  readalizer_customer: 'e9d463e2-3086-4e8a-9e6e-19fc18ba17c9',
  whitelabel: require('./whitelabel/default.js').default
};
