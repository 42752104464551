import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-hint-box',
  templateUrl: './hint-box.component.html',
  styleUrls: ['./hint-box.component.scss'],
})
export class HintBoxComponent implements OnInit {
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() id!: string;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  public isClosed: boolean = true;
  private prefix = 'hint-state-';

  ngOnInit(): void {
    this.isClosed = this.isBoxClosed(this.id);
  }

  handleClose(): void {
    this.isClosed = true;
    this.closeBox(this.id);
    this.onClose.emit();
  }

  isBoxClosed(id: string): boolean {
    return localStorage.getItem(this.prefix + id) === 'closed';
  }

  closeBox(id: string): void {
    localStorage.setItem(this.prefix + id, 'closed');
  }

  openBox(id: string): void {
    localStorage.removeItem(this.prefix + id);
  }
}
