import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { GuardsCheckEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { DiagnosticCreateComponent } from '../../diagnostic/diagnostic-create/diagnostic-create.component';
import { VideoOverlayComponent } from '../../helpers/video-overlay/video-overlay.component';
import { LicenseService } from '../../providers/license.service';
import { TrainingTaskCreateComponent } from '../../training-task/training-task-create/training-task-create.component';
import { TrialBannersComponent } from 'src/app/helpers/trial-banners/trial-banners.component';
import { TeacherService } from 'src/app/providers/teacher.service';

export interface Tasks {}

@Component({
  selector: 'app-teacher-sidebar',
  templateUrl: './teacher-sidebar.component.html',
  styleUrls: ['./teacher-sidebar.component.scss'],
})
export class TeacherSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @Output('reduceSidebar') reduceSidebar = new EventEmitter<boolean>();
  collapsedSidebar: boolean = true;
  support_url = environment.whitelabel.support_url;
  externalAssetsUrl = environment.externalAssets;
  userType;
  diagnosticLicenseStatusActive: boolean = false;
  trainingLicenseStatusActive: boolean = false;
  currentUrlSlug;
  translatedText: any;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
    public router: Router,
    private authService: AuthService,
    private licenseService: LicenseService,
    private teacherService: TeacherService,
    private translationService: TranslationService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckEnd) {
        this.currentUrlSlug = event.url.split('/')[1];
      }
    });
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.userType = this.authService.getType();
    this.licenseService
      .getActiveLicensesUpdatedListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((status) => {
        this.diagnosticLicenseStatusActive =
          status.di == 'active' ? true : false;
        this.trainingLicenseStatusActive = status.fo == 'active' ? true : false;
      });
    this.breakpointObserver
      .observe(['(min-width: 1199px)'])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.collapsedSidebar = false;
        } else {
          this.collapsedSidebar = true;
        }
      });

    this.trialStatus = this.authService.getUserTrialStatus();
  }

  handleSidebar(): void {
    this.collapsedSidebar = !this.collapsedSidebar;
    this.reduceSidebar.emit(this.collapsedSidebar);
  }

  openSidebarMenu() {
    // close menu again when trial expired to not allow task creation
    if (
      this.trialStatus.isActive &&
      this.trialStatus.expiryDate.getTime() < Date.now()
    ) {
      this.openTrialBanner(this.trialStatus);
      this.menuTrigger.closeMenu();
    }
  }

  openTrialBanner(trialStatus): void {
    // this.showTrialBanner = true;
    let dialogRef = this.dialog.open(TrialBannersComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'trial-banner-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {
        trialStatus: trialStatus,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }
  openUpgradeModal() {
    // TODO: Better upgradeModalText
    this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
      UpgradeDialogComponent,
      {
        width: '85vw',
        autoFocus: false,
        disableClose: true,
        panelClass: 'home-trial-dialog-panel',
        // hasBackdrop: false,
        backdropClass: 'license-add-backdrop',
        data: { upgradeModalText: 'maxStudentsReached' },
      },
    );
  }

  createTaskDialog(): void {
    let dialogRef = this.dialog.open(TrainingTaskCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      //animation: { to: 'left' },
      //position: { rowEnd: '10%' },
    });
    // dialogRef.disableClose = true;
    // dialogRef.afterClosed().subscribe();
  }

  showVideo(filename, title) {
    // TODO: unused var?
    let dialogRef = this.dialog.open(VideoOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'video-overlay-panel',
      // hasBackdrop: false,
      disableClose: false,
      backdropClass: 'video-overlay-panel-backdrop',
      data: {
        title: title,
        url: this.externalAssetsUrl + '/assets/videos/' + filename,
      },
    });
  }

  createDiagnoseDialog() {
    let dialogRef = this.dialog.open(DiagnosticCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    // dialogRef.afterClosed().subscribe();
  }

  openExternalUrl(url: string) {
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
